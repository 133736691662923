@media (max-width: 576px) {
  .headline {
    font-size: 3.5rem;
    color: var(--primary-color);
    font-weight: 350;
  }

  .underline {
    font-size: 1.025rem;
    color: var(--text-color);
  }
}

@media (min-width: 577px) {
  .headline {
    font-size: 5.5rem;
    color: var(--primary-color);
    font-weight: 300;
  }

  .underline {
    font-size: 1.5rem;
    color: var(--text-color);

  }
}