* {
  font-family: 'Open Sans', sans-serif;
}

body {
  margin: 0;
background-color: black;

}

* {
  --primary-color: #0873e8;
  --text-color-light: #d4d4d4;
  --text-color: white;
}

