
.activities a {
  padding: 10px;
  
}

.activities img {
  margin: 0 0.0rem;
  border-radius: 5px;


}

@media (min-width: 577px) {
  .activities {
    margin-top: 12vh;
    display: flex;
    flex-direction: column;
    justify-content: center;  
    height: 100%;
  }

  .noze {
    width: 250px;
  }

  .yk {
    width: 425px;
    margin-top: 28px;
  }

  .teaching {
    margin-left: 100px;
    margin-top: 10px;
    border-radius: 5px;
    text-decoration: none;
    width: 340px;
    height: 52px;
    background-color: white;
  }

  .lecture {
    color: red;
    margin-left: 10px;
    font-size: 40px; 
    text-decoration: none;
    font-weight: bold;
    vertical-align: top;
  }

  .graduate {
    color: red;
    font-size: 54px;
  }

}
@media (max-width: 576px) {

  .activities {
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

  }

  .noze{
    width: 150px;
  }
  
  .yk {
    width: 250px;
  }

  .teaching {
    margin-left: 52px;
    margin-top: 10px;
    border-radius: 5px;
    text-decoration: none;
    width: 250px;
    height: 32px;
    background-color: white;
  }

  .lecture {
    color: red;
    font-size: 29px; 
    text-decoration: none;
    font-weight: bold;
    vertical-align: top;
  }

  .graduate {
    color: red;
    font-size: 40px;
    margin-top: -4px;
    margin-right: 4px;
  }
}